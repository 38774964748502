import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '../assets/styles/theme'

const AppRoot = ({ Component, pageProps }: AppProps) => {

  useEffect(() => {

    // Helper function
    const domReady = (cb: () => void) => {
      document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
    };

    domReady(() => {
      // Display body when DOM is loaded
      document.body.style.visibility = 'visible';
    });

  }, [])

  
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ThemeProvider theme={theme}>
        <Script src="https://kit.fontawesome.com/ceecfad9a8.js" crossOrigin="anonymous" />
        <Script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Xf4Ssr" />
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}

export default AppRoot
