import { createGlobalStyle, DefaultTheme } from 'styled-components';
import Typography from './typography'

// eslint-disable-next-line no-unused-vars
const smallPhone = 480; // XS
// Small devices (landscape phones, 576px and down)
const phone = 576; // SM
// smallTablet devices (tablets, 768px and down)
const smallTablet = 768; // MD
// tablet devices (desktops, 1024 and down)
const tablet = 1024; // LG
// Extra tablet devices (tablet desktops, 1200px and down)
const smallDesktop = 1200; // XLG

const desktop = 1440; // XXL

const largeDesktop = 1600; // XXXL

const desktopFHD = 1920; // XXXXL

const desktopQHD = 2560; // XXXXXL

const desktop4K = 3840; // XXXXXXL

const theme = {
  mediaQuery: {
    smallPhone: `(min-width: ${smallPhone}px)`,
    phone: `(min-width: ${phone}px)`,
    smallTablet: `(min-width: ${smallTablet}px)`,
    // because Ipad mini and regular Ipad share the same viewport (1024 width)
    // required to allow the filter to hide
    tablet: `(min-width: ${tablet}px)`,
    ipad: `(min-width: ${tablet + 1}px) and (min-height: ${smallTablet}px)`,
    smallDesktop: `(min-width: ${smallDesktop}px)`,
    desktop: `(min-width: ${desktop}px)`,
    largeDesktop: `(min-width: ${largeDesktop}px)`,
    desktopFHD: `(min-width: ${desktopFHD}px)`,
  },
  fonts: {
    primary: "AspektaVF, sans-serif",
    secondary: "'Urbanist', sans-serif"
  },
  colors: {
    midGrey: '#666',
    lightGrey: '#999',
    transparentGrey: 'rgba(0, 0, 0, 0.33)',
  }
};

export type ThemeType = typeof theme;
 
const GlobalStyle = createGlobalStyle<{theme: DefaultTheme}>`

  @font-face {
    font-family: 'AspektaVF';
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
    src: url('/fonts/AspektaVF.woff2') format('woff2');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: ${theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    font-smooth: auto;
  }

  ${Typography}

  img {
    width: 100%;
  }

  .icon-link {
    font-size: 1.5rem;
    display: inline-block;
    color: white;
    padding: 0.5rem;
  }
`;
 
export {
  smallPhone,
  phone,
  smallTablet,
  tablet,
  smallDesktop,
  desktop,
  largeDesktop,
  desktopFHD,
  desktopQHD,
  desktop4K,
  theme,
  GlobalStyle
};